<template>
  <li class="header__actions">
    <v-text-field :value="answer.text"
                  @input="$emit('update-answer-text', { id: answer.id, value: $event })"
                  label="Texte de la réponse"
                  outlined
    />
    <v-text-field :value="answer.value"
                  @input="$emit('update-answer-value', { id: answer.id, value: $event })"
                  label="Valeur de la réponse"
                  outlined
    />
    <div>
      <v-icon @click="$emit('delete-answer', answer)">mdi-trash-can-outline</v-icon>
    </div>
  </li>
</template>

<script>
export default {
  name: 'AdminFormQuestionAnswer',

  props: {
    answer: {
      required: true,
      type: Object,
    },
  },
}
</script>

<style lang="scss" scoped>
@import "headerActions";

.header__actions {
  grid-template-columns: 1fr 1fr 50px;
}
</style>
